/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const loginSignupForgot = async (data, type) => {
  try {
    let url = '';
    if (type === 'login') url = '/api/v1/users/login';
    else if (type === 'signup') url = '/api/v1/users/signup';
    else url = '/api/v1/users/forgotPassword';

    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', `${type} was successfull`);

      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
    console.log(res);
  } catch (error) {
    showAlert('error', error.response.data.message);
  }
};

export const logout = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: '/api/v1/users/logout'
    });
    if (res.data.status === 'success') window.location.replace('/');
  } catch (error) {
    showAlert('error', 'Error logging out');
  }
};

export const resetPassword = async (data, token) => {
  try {
    const url = `/api/v1/users/resetPassword/${token}`;
    const res = await axios({
      method: 'PATCH',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert(
        'success',
        `Your password was reset, you can access your account now`
      );

      window.setTimeout(() => {
        window.location.replace('/');
      }, 1500);
    }
    console.log(res);
  } catch (error) {
    showAlert('error', error.response.data.message);
  }
};
