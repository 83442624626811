/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const createReview = async data => {
  const url = '/api/v1/reviews';
  try {
    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', `Your review has been successfully posted`);

      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
    console.log(res);
  } catch (error) {
    showAlert('error', error.response.data.message);
  }
};
