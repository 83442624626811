/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const createBooking = async data => {
  const url = '/api/v1/bookings';
  try {
    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', `Booking has been created successfully`);

      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
    console.log(res);
  } catch (error) {
    showAlert('error', error.response.data.message);
  }
};

export const createMessage = async data => {
  const url = '/api/v1/messages';
  try {
    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', `message has been created successfully`);

      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
    console.log(res);
  } catch (error) {
    showAlert('error', error.response.data.message);
  }
};

export const acceptMessage = async data => {
  const url = '/api/v1/bookings';
  try {
    const res = await axios({
      method: 'Patch',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', `message has been accept successfully`);

      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    } else {
      showAlert('error', res.data.data.message);
    }
    console.log(res);
  } catch (error) {
    showAlert('error', error.response.data.message);
  }
};

export const declinedMessage = async data => {
  const url = '/api/v1/bookings';
  try {
    const res = await axios({
      method: 'Patch',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', `message has been decline successfully`);

      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
    console.log(res);
  } catch (error) {
    showAlert('error', error.response.data.message);
  }
};
