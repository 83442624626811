/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const updateReview = async (data, id) => {
  try {
    const url = `/api/v1/reviews/${id}`;
    const res = await axios({
      method: 'PATCH',
      url,
      data: {
        status: data
      }
    });

    console.log(res);

    if (res.data.status === 'success') {
      showAlert('success', `You have successfully update this review`);

      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};
