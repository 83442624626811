/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const addListing = async (data, type, id) => {
  try {
    const url = type === 'add' ? '/api/v1/listings' : `/api/v1/listings/${id}`;
    const res = await axios({
      method: type === 'add' ? 'POST' : 'PATCH',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Your add was successfully posted!');

      id = res.data.data.data._id;
      // console.log(id);
      // debugger;

      window.setTimeout(() => {
        location.assign(`/${id}`);
      }, 1500);
    }
  } catch (error) {
    showAlert('error', error.response.data.message);
    console.log(error.response);
  }
};
