/* eslint-disable */

export const hideAlert = () => {
  const el = document.querySelector('.alert');
  if (el) el.parentNode.removeChild(el);
};

// Type can be either success or error
export const showAlert = (type, msg, time = 7) => {
  hideAlert();
  const markup = `<div class="alert custom--alert alert--${type} text-sentence">${msg}
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
  <span aria-hidden="true">&times;</span></button></div>`;
  document.querySelector('body').insertAdjacentHTML('afterbegin', markup);
  window.setTimeout(hideAlert, time * 1000);
};
