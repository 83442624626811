/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const createSupportEnquiry = async data => {
  const url = '/api/v1/support';
  try {
    const res = await axios({
      method: 'POST',
      url,
      data
    });

    if (res.data.status === 'success') {
      showAlert(
        'success',
        `Your enquiry has been sent, we will be in touch with you shortly`
      );

      window.setTimeout(() => {
        window.location.replace('/');
      }, 1500);
    }
    console.log(res);
  } catch (error) {
    showAlert('error', error.response.data.message);
  }
};

export const updateSupport = async (data, id) => {
  try {
    const url = `/api/v1/support/${id}`;
    const res = await axios({
      method: 'PATCH',
      url,
      data: {
        status: data
      }
    });

    if (res.data.status === 'success') {
      showAlert('success', `You have successfully update this ticket`);

      window.setTimeout(() => {
        location.reload(true);
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};
