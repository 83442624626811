/* eslint-disable */
import '@babel/polyfill';
import { displayMap } from './mapbox';
import { loginSignupForgot, logout, resetPassword } from './login';
import { updateSettings } from './updateSettings';
import { showAlert } from './alerts';
import { updateReview } from './updateReview';
import { addListing } from './addListing';
import { createReview } from './review';
import { createSupportEnquiry, updateSupport } from './support';
import {
  createBooking,
  createMessage,
  acceptMessage,
  declinedMessage
} from './bookingReq';

// DOM Elements
const mapBox = document.getElementById('map');
const loginForm = document.querySelector('.form--login');
const signupForm = document.querySelector('.form--signup');
const supportForm = document.querySelector('.form--support');
const forgotForm = document.querySelector('.form--forgot');
const logoutBtn = document.querySelector('.nav__el--logout');
const userDataForm = document.querySelector('.form-user-data');
const userPasswordForm = document.querySelector('.form-user-settings');
const resetPasswordForm = document.querySelector('.form--reset--password');
const bookBtn = document.getElementById('book-tour');
const addListingForm = document.querySelector('.add-listing');
const editListingForm = document.querySelector('.edit-listing');
const createBookingForm = document.querySelector('.create-booking');
const createMessageForm = document.querySelector('.message-form');
const acceptMessageForm = document.querySelector('.acceptMessage-form');
const declineMessageForm = document.querySelector('.declineMessage-form');
const createReviewForm = document.querySelectorAll('.create-review');
const updateReviewForm = document.querySelectorAll('.update-review');
const updateSupportForm = document.querySelectorAll('.update-support');
// Values

// Delegation
if (mapBox) {
  const locations = JSON.parse(mapBox.dataset.locations);
  displayMap(locations);
}

if (loginForm) {
  loginForm.addEventListener('submit', async event => {
    event.preventDefault();
    document.querySelector('.btn--loading').textContent = 'Processing...';

    const email = document.getElementById('emailLogin').value;
    const password = document.getElementById('passwordLogin').value;
    await loginSignupForgot({ email, password }, 'login');

    document.querySelector('.btn--loading').textContent = 'Login';
  });
}

if (signupForm) {
  signupForm.addEventListener('submit', async event => {
    event.preventDefault();
    document.querySelector('.btn--loading--signup').textContent =
      'Processing...';

    const name = document.getElementById('signupName').value;
    const email = document.getElementById('signupEmail').value;
    const password = document.getElementById('signupPassword').value;
    const passwordConfirm = document.getElementById('signupPasswordConfirm')
      .value;
    await loginSignupForgot(
      { name, email, password, passwordConfirm },
      'signup'
    );

    document.querySelector('.btn--loading--signup').textContent = 'Signup';
  });
}

if (supportForm) {
  supportForm.addEventListener('submit', async event => {
    event.preventDefault();
    document.querySelector('#js-contact-btn').textContent = 'Processing...';

    const user = document.getElementById('supportUserId').value;
    const name = document.getElementById('supportName').value;
    const email = document.getElementById('supportEmail').value;
    const listing = document.getElementById('supportListingURL').value;
    const message = document.getElementById('supportMessage').value;
    await createSupportEnquiry({ user, name, email, listing, message });

    document.querySelector('#js-contact-btn').textContent = 'SEND';
  });
}

if (forgotForm) {
  forgotForm.addEventListener('submit', async event => {
    event.preventDefault();
    document.querySelector('.btn--loading--forgot').textContent =
      'Processing...';

    const email = document.getElementById('forgotEmail').value;
    await loginSignupForgot({ email }, 'forgot');

    document.querySelector('.btn--loading--forgot').textContent =
      'RESET PASSWORD';
  });
}

if (resetPasswordForm) {
  resetPasswordForm.addEventListener('submit', async event => {
    event.preventDefault();
    document.querySelector('.btn--loading--forgot').textContent =
      'Processing...';

    const token = document.getElementById('resetToken').value;
    const password = document.getElementById('resetPassword').value;
    const passwordConfirm = document.getElementById('resetPasswordConfirm')
      .value;

    await resetPassword({ password, passwordConfirm }, token);

    document.querySelector('.btn--loading--forgot').textContent =
      'RESET PASSWORD';
  });
}

if (logoutBtn) logoutBtn.addEventListener('click', logout);

if (userDataForm) {
  userDataForm.addEventListener('submit', event => {
    event.preventDefault();
    const form = new FormData();
    form.append('name', document.getElementById('name').value);
    form.append('email', document.getElementById('email').value);
    form.append('addressSuburb', document.getElementById('locality').value);
    form.append(
      'addressState',
      document.getElementById('administrative_area_level_1').value
    );
    form.append('addressCountry', document.getElementById('country').value);
    form.append('addressLat', document.getElementById('lat').value);
    form.append('addressLng', document.getElementById('lng').value);
    form.append('photo', document.getElementById('photo').files[0]);

    updateSettings(form, 'data');
  });
}

if (userPasswordForm) {
  userPasswordForm.addEventListener('submit', async event => {
    event.preventDefault();
    document.querySelector('.btn-save-password').textContent = 'Updatig...';

    const currentPassword = document.getElementById('password-current').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password-confirm').value;
    await updateSettings(
      { currentPassword, password, passwordConfirm },
      'password'
    );

    document.querySelector('.btn-save-password').textContent = 'Save password';
    document.getElementById('password-current').value = '';
    document.getElementById('password').value = '';
    document.getElementById('password-confirm').value = '';
  });
}

if (createReviewForm.length > 0) {
  createReviewForm.forEach(form => {
    let formId = form.id;
    form.addEventListener('submit', async event => {
      event.preventDefault();

      const reviewType = $('#' + formId + ' .reviewType').val();
      const rating = $('#' + formId + ' input[name="rate"]:checked').val();
      const listing = $('#' + formId + ' .listing').val();
      const title = $('#' + formId + ' .title').val();
      const review = $('#' + formId + ' .review').val();
      const reviewer = $('#' + formId + ' .reviewer').val();
      const reviewee = $('#' + formId + ' .reviewee').val();
      const booking = $('#' + formId + ' .booking').val();
      const images = document.getElementById('imageNames').value;

      createReview({
        title,
        review,
        reviewType,
        rating,
        listing,
        reviewer,
        reviewee,
        booking,
        images
      });
    });
  });
}

if (updateReviewForm.length > 0) {
  updateReviewForm.forEach(form => {
    let formId = form.id;
    form.addEventListener('submit', async event => {
      event.preventDefault();

      const reviewStatus = $('#' + formId + ' .review-status').val();

      await updateReview(reviewStatus, formId);
    });
  });
}

if (updateSupportForm.length > 0) {
  updateSupportForm.forEach(form => {
    let formId = form.id;
    form.addEventListener('submit', async event => {
      event.preventDefault();

      const supportStatus = $('#' + formId + ' .support-status').val();

      await updateSupport(supportStatus, formId);
    });
  });
}

if (bookBtn) {
  bookBtn.addEventListener('click', event => {
    event.target.textContent = 'Processing...';
    const { tourId } = event.target.dataset;
    bookTour(tourId);
  });
}

if (addListingForm) {
  addListingForm.addEventListener('submit', async event => {
    event.preventDefault();
    document.querySelector('.post--button').textContent = 'Processing...';

    const tag = Array(...document.getElementById('tags').options).reduce(
      (acc, option) => {
        if (option.selected === true) {
          acc.push(option.value);
        }
        return acc;
      },
      []
    );

    const user = document.getElementById('user').value;
    const name = document.getElementById('listingName').value;
    const tags = tag;
    const colour = document.getElementById('colour').value;
    const price = document.getElementById('price').value;
    const fabric = document.getElementById('fabric').value;
    const brand = document.getElementById('brand').value;
    const summary = document.getElementById('summary').value;
    const description = document.getElementById('description').value;
    const size = document.getElementById('size').value;
    const sizingSystem = document.getElementById('sizingSystem').value;
    const bust = document.getElementById('bust').value;
    const waist = document.getElementById('waist').value;
    const hip = document.getElementById('hip').value;
    const height = document.getElementById('height').value;
    const dryCleaned = document.getElementById('dryCleaned').checked;
    const category = document.getElementById('category').value;
    const images = document.getElementById('imageNames').value;

    const imagesCount = images.split(',').filter(image => {
      return image;
    }).length;
    if (imagesCount > 0) {
      await addListing(
        {
          user,
          name,
          tags,
          colour,
          price,
          fabric,
          brand,
          summary,
          description,
          size,
          sizingSystem,
          bust,
          waist,
          hip,
          height,
          dryCleaned,
          category,
          images
        },
        'add'
      );

      document.querySelector('.post--button').textContent = 'POST YOUR AD';
    }
  });
}

if (editListingForm) {
  editListingForm.addEventListener('submit', async event => {
    event.preventDefault();
    document.querySelector('.btn--loading').textContent = 'Processing...';

    const tag = Array(...document.getElementById('tags').options).reduce(
      (acc, option) => {
        if (option.selected === true) {
          acc.push(option.value);
        }
        return acc;
      },
      []
    );

    const id = window.location.pathname.split('/')[2];
    const user = document.getElementById('user').value;
    const name = document.getElementById('listingName').value;
    const tags = tag;
    const colour = document.getElementById('colour').value;
    const price = document.getElementById('price').value;
    const fabric = document.getElementById('fabric').value;
    const brand = document.getElementById('brand').value;
    const summary = document.getElementById('summary').value;
    const description = document.getElementById('description').value;
    const size = document.getElementById('size').value;
    const sizingSystem = document.getElementById('sizingSystem').value;
    const bust = document.getElementById('bust').value;
    const waist = document.getElementById('waist').value;
    const hip = document.getElementById('hip').value;
    const height = document.getElementById('height').value;
    const dryCleaned = document.getElementById('dryCleaned').checked;
    const category = document.getElementById('category').value;
    const images = document.getElementById('imageNames').value;

    await addListing(
      {
        id,
        user,
        name,
        tags,
        colour,
        price,
        fabric,
        brand,
        summary,
        description,
        size,
        sizingSystem,
        bust,
        waist,
        hip,
        height,
        dryCleaned,
        category,
        images
      },
      'edit',
      id
    );

    document.querySelector('.btn--loading').textContent = 'Submit';
  });
}

if (createBookingForm) {
  createBookingForm.addEventListener('submit', async event => {
    event.preventDefault();
    document.querySelector('.btn--loading--request--booking').textContent =
      'Processing...';

    const listing = $('#listing').val();
    const owner = $('#owner').val();
    const customer = $('#customer').val();
    const price = $('#price').val();
    const startDate = $('#startDate').val();
    const endDate = $('#endDate').val();
    const text = $('#text').val();

    await createBooking({
      listing,
      owner,
      customer,
      price,
      startDate,
      endDate,
      text
    });

    document.querySelector('.btn--loading--request--booking').textContent =
      'SEND YOUR REQUEST';
  });
}

if (createMessageForm) {
  createMessageForm.addEventListener('submit', async event => {
    event.preventDefault();
    // document.querySelector('.btn--loading').textContent = 'Processing...';
    const booking = $('#booking').val();
    const from = $('#from').val();
    const to = $('#to').val();
    const text = $('#text').val();

    await createMessage({ booking, from, to, text });

    // document.querySelector('.btn--loading').textContent = 'Login';
  });
}

if (acceptMessageForm) {
  acceptMessageForm.addEventListener('submit', async event => {
    event.preventDefault();
    // document.querySelector('.btn--loading').textContent = 'Processing...';
    const id = $('.acceptMessage-form #id').val();
    const status = $('.acceptMessage-form #status').val();
    await acceptMessage({ id, status });

    // document.querySelector('.btn--loading').textContent = 'Login';
  });
}

if (declineMessageForm) {
  declineMessageForm.addEventListener('submit', async event => {
    event.preventDefault();
    // document.querySelector('.btn--loading').textContent = 'Processing...';
    const id = $('.declineMessage-form #id').val();
    const status = $('.declineMessage-form #status').val();

    await declinedMessage({ id, status });

    // document.querySelector('.btn--loading').textContent = 'Login';
  });
}

// Show alert after booking
const alertMessage = document.querySelector('body').dataset.alert;
if (alertMessage) showAlert('success', alertMessage, 20);

// Show modal popup for review update
const modals = document.getElementsByClassName('modal');
// Get the button that opens the modal
const btns = document.getElementsByClassName('openmodal');
const spans = document.getElementsByClassName('close');
for (let i = 0; i < btns.length; i++) {
  btns[i].onclick = function() {
    modals[i].style.display = 'block';
  };
}
for (let i = 0; i < spans.length; i++) {
  spans[i].onclick = function() {
    modals[i].style.display = 'none';
  };
}

if ($('#chat-window').length > 0) {
  $('#chat-window').scrollTop($('#chat-window')[0].scrollHeight);
  let socket = io.connect('http://localhost:3000');
  socket.on('new_message', data => {
    location.reload(true);
  });
}
// Chat window scroll to bottom

Dropzone.autoDiscover = false;
$(document).ready(function() {
  // eslint-disable-next-line no-undef
  $('#dZUpload').dropzone({
    addRemoveLinks: true,
    autoProcessQueue: false,
    uploadMultiple: true,
    parallelUploads: 100,
    maxFiles: 3,
    paramName: 'images',
    clickable: true,
    url: '/api/v1/listings/images',
    createImageThumbnails: true,
    init: function() {
      myDropzone = this;

      $('#dZUpload').sortable({
        items: '.dz-preview',
        cursor: 'move',
        opacity: 0.5,
        containment: '#dZUpload',
        distance: 20,
        tolerance: 'pointer',
        stop: function() {
          let queue = myDropzone.files;
          let newQueue = [];
          $('#dZUpload .dz-preview .dz-filename [data-dz-name]').each(function(
            count,
            el
          ) {
            var name = el.innerText;
            queue.forEach(function(file) {
              if (file.name === name) {
                newQueue.push(file);
              }
            });
          });

          myDropzone.files = newQueue;
        }
      });

      if ($('#imageNames').val()) {
        let imagesObj = {};
        const imageNames = $('#imageNames')
          .val()
          .split(',');
        for (let i = 0; i < imageNames.length; i++) {
          const imageName = imageNames[i];
          if (imageName !== 'null') {
            var mockFile = {
              name: imageName,
              size: 12345
            };
            myDropzone.emit('addedfile', mockFile);
            myDropzone.emit(
              'thumbnail',
              mockFile,
              'https://listed-node.s3-ap-southeast-2.amazonaws.com/' + imageName
            );

            myDropzone.emit('complete', mockFile);
          }
        }
      }

      const $form = $('.listing-images');
      // Update selector to match your button
      $form.submit(function(e) {
        this.uploadSuccess = false;
        e.preventDefault();
        myDropzone.processQueue();
        if (
          myDropzone.files.length == 0 ||
          myDropzone.files.length ==
            myDropzone.files.filter(file => {
              return file.status == 'success';
            }).length
        ) {
          $('#listing-submit-btn').trigger('click');
        }
        return false;
      });

      this.on('sending', function(file, xhr, form) {
        form.append('id', document.getElementById('id').value);
      });
    },
    removedfile: function(file) {
      if (
        file.previewElement != null &&
        file.previewElement.parentNode != null
      ) {
        file.previewElement.parentNode.removeChild(file.previewElement);
        let imageNames = $('#imageNames')
          .val()
          .split(',');
        if (imageNames.indexOf(file.name) > -1) {
          imageNames.splice(imageNames.indexOf(file.name), 1);
        }
        $('#imageNames').val(imageNames);
      }
      return this._updateMaxFilesReachedClass();
    },
    error: function(file, response) {
      if (response.message) {
        showAlert('error', response.message);
      } else {
        showAlert('error', response);
      }
      console.log(response);
      if (!file.accepted) {
        if (
          file.previewElement != null &&
          file.previewElement.parentNode != null
        ) {
          file.previewElement.parentNode.removeChild(file.previewElement);
        }
        return this._updateMaxFilesReachedClass();
      }
    },
    successmultiple: function(file, response) {
      let imageNames = $('#imageNames')
        .val()
        .split(',');
      imageNames = imageNames.concat(response.data.images);
      $('#imageNames').val(imageNames);
      this.uploadSuccess = true;
    },
    completemultiple: function(file, response) {
      if (this.uploadSuccess) {
        $('#listing-submit-btn').trigger('click');
      }
    }
  });
});
